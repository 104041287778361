<mat-toolbar color="primary">
    <div class="w-full flex justify-between items-center">
        <div class="flex justify-between items-center">
            <div>
                <button (click)="onToggleSidenav()" mat-icon-button>
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
            <div>
                <a [routerLink]="['/']" class="ml-2 logo" routerLinkActive="active">
                    LIDO
                </a>
            </div>
        </div>

        <div class="flex justify-between items-center">
            <div *ngIf="user?.organizations?.length && isLoggedIn" class="mx-2">

                <button [matMenuTriggerFor]="orgs" mat-button>
                    <mat-icon>store</mat-icon>
                    <span *ngIf="currentOrg">Acting for {{currentOrg.name}}</span>
                    <span *ngIf="!currentOrg">Select Organization</span>
                </button>

                <mat-menu #orgs="matMenu">
                    <button (click)="selectOrganization(org.name)" *ngFor="let org of user?.organizations" mat-menu-item>
                        {{org.name}}
                    </button>
                </mat-menu>
            </div>

            <div class="mx-2 justify-end hidden sm:flex">
                <ul class="flex gap-5 navigation-items">
                    <li *ngIf="!isLoggedIn"><a [routerLink]="['/login']" routerLinkActive="active">Login</a></li>
                    <li *ngIf="!isLoggedIn"><a [routerLink]="['/signup']" routerLinkActive="active">Signup</a></li>
                    <li *ngIf="isLoggedIn"><a (click)="logout()" class="cursor-pointer text-lg">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</mat-toolbar>
