import {Routes} from '@angular/router';

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadComponent: () => import('./home/home.component').then(x => x.HomeComponent)
    },
    {path: 'login', loadComponent: () => import('./login/login.component').then(x => x.LoginComponent)},
    {path: 'signup', loadComponent: () => import('./signup/signup.component').then(x => x.SignupComponent)},
    {
        path: 'orders',
        loadChildren: () =>
            import('./orders/orders-routing.module'),
    },
    {
        path: 'receiving',
        loadChildren: () =>
            import('./receiving/receiving-routing.module'),
    },
    {
        path: 'shipment',
        loadChildren: () =>
            import('./shipment/shipment-routing.module'),
    },
    {
        path: 'recon',
        loadChildren: () =>
            import('./reconciliation/reconciliation-routing.module'),
    },
    {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory-routing.module')
    },
    {
        path: 'users',
        loadChildren: () =>
            import('./users/users-routing.module'),
    },
    {
        path: 'suppliers',
        loadChildren: () =>
            import('./suppliers/suppliers-routing.module'),
    },
    {
        path: 'invoices',
        loadChildren: () =>
            import('./invoice/invoice-routing.module'),
    },
    {
        path: 'payments',
        loadChildren: () =>
            import('./payments/payments-routing.module'),
    },
    {
        path: 'organizations',
        loadChildren: () =>
            import('./organizations/organization-routing.module'),
    },
    {
        path: 'product',
        loadChildren: () =>
            import('./products/products-routing.module'),
    },
    {
        path: 'reports',
        loadChildren: () =>
            import('./reports/reports-routing.module'),
    },
    {
        path: 'my-organization',
        loadChildren: () =>
            import('./my-organization/my-organization-routing.module'),
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
    },
];