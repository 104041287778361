<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
        <app-sidenav-list (closeSidenav)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="flex flex-col h-full">
            <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
                <main class="h-full">
                        <router-outlet></router-outlet>
                </main>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
