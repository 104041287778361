import {enableProdMode, importProvidersFrom, NgModule} from '@angular/core';

import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {APP_ROUTES} from './app/app-routing.module';
import {provideAnimations} from '@angular/platform-browser/animations';
import {JwtModule} from '@auth0/angular-jwt';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CurrentOrgInterceptor} from './app/_interceptors/current-org-interceptor';
import {provideRouter} from '@angular/router';

if (environment.production) {
    enableProdMode();
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        full: 'MMMM DD, YYYY'
    }
};

export function getToken() {
    return localStorage.getItem('lido-bo-token');
}

function _allowedDomains(): string[] {
    let domains: string[] = [];
    domains = domains.concat(['http://lidoapi.org', 'https://lidoapi.org']);
    if (!environment.production) domains.push('localhost:8888');
    return domains;
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            JwtModule.forRoot({
                config: {
                    tokenGetter: getToken,
                    allowedDomains: _allowedDomains(),
                    disallowedRoutes: ['localhost:8888/api/auth', 'http://lidoapi.org/api/auth', 'https://lidoapi.org/api/auth'],
                }
            })
        ),
        provideRouter(APP_ROUTES),
        provideAnimations(),
        provideHttpClient(
            withInterceptorsFromDi()
        ),
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
        {provide: HTTP_INTERCEPTORS, useClass: CurrentOrgInterceptor, multi: true},
    ]
}).catch(err => console.error(err));

