import {AuthService} from 'src/app/_services/auth.service';
import {SidenavService} from '../../_services/sidenav.service';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {SidenavLink} from '../../_models/SidenavLink';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';

@Component({
    selector: 'app-sidenav-list',
    templateUrl: './sidenav-list.component.html',
    styleUrls: ['./sidenav-list.component.css'],
    standalone: true,
    imports: [MatListModule, MatIconModule,CommonModule,RouterModule, MatButtonModule, CommonModule],
})
export class SidenavListComponent implements OnInit, OnDestroy {
    @Output() closeSidenav = new EventEmitter<void>();
    links: any[] = [];
    linksSub: Subscription;

    navLinks: any[] = [];
    navLinksSub: Subscription;

    constructor(private sidenavService: SidenavService, private auth: AuthService) {
    }

    ngOnInit() {
        this.linksSub = this.sidenavService.sectionLinksSub.subscribe((links: any[]) => {
            this.links = links;
        });

        this.navLinksSub = this.sidenavService.navLinksSub.subscribe((links: SidenavLink[]) => {
            this.navLinks = links;
        });
    }

    ngOnDestroy() {
        this.linksSub.unsubscribe();
        this.navLinksSub.unsubscribe();
    }

    onClose() {
        this.closeSidenav.emit();
    }

    loggedIn(): boolean {
        return this.auth.isLoggedIn;
    }

    logout() {
        this.auth.logout();
        this.onClose();
    }
}
