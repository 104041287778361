import {Component} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './navigation/header/header.component';
import {SidenavListComponent} from './navigation/sidenav-list/sidenav-list.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [MatSidenavModule, RouterModule, HeaderComponent, SidenavListComponent],
})
export class AppComponent {
    private _routeFiltersStorageKey = 'bo-route-filters';

    constructor() {
        localStorage.removeItem(this._routeFiltersStorageKey);
    }
}
